@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');

body, html {
  background: #292333;
  font-family: 'Nunito';
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h1{
  font-weight: 700;
  color: #292333;
  font-size: 30px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper{

}
.wrapperInner{
  padding-top:60px;
}
.content{

}
.formBox{
  background: #fff;
  padding:25px 25px 25px 25px;
  overflow: hidden;
  position: relative;
  min-height:550px;
  border-radius: 15px;
}
.formBox h1{
  text-align: center;
  margin-bottom: 30px;
}
.formBoxHeader{
  background:#FDDA00;
  position: absolute;
  border-radius: 140%;
  width: 116%;
  height: 300px;
  top: -135px;
  left: -8%;
}
.formBoxLogo{
  z-index: 100;
  position:relative;
  text-align: center;
  margin-bottom:50px;
}

.footerBlock{
  margin-top:20px;
  text-align: center;
  color:#9F9AA6;
}
.ant-input-affix-wrapper{
  border-radius:10px;
  padding:10px 15px;
  margin-bottom:5px;
  margin-top:2px;
  border-top:3px solid #FDDA00;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover{
  border-color: #FDDA00 !important;
}
.ant-input-prefix{
  margin-right: 10px;
}
.formBoxForm{
  margin-top:10px;
}
.formButton{
  width:100%;
  background-color:#EF4E34;
  color:#fff;
  border:0;
  padding:10px 15px;
  border-radius:20px;
  height:auto;
  font-size:18px;
  margin-top:10px;
  font-weight: 700;
}
.formButton:hover,
.formButton:focus{
  background-color:#EF614A;
}
.formBoxLabel{
  color:#737373;
  
}
.ant-alert{
  border-radius:5px;
}